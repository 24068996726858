import React, {useMemo, useState, useContext, useCallback, useEffect} from 'react'
import type { MenuProps } from 'antd';
import { Typography, Menu, theme, Space, Button, Tag, ConfigProvider }  from 'antd';
import {DashboardOutlined, DollarOutlined, TableOutlined, SettingOutlined, RocketOutlined, UserOutlined, FileProtectOutlined, FileAddOutlined, EditOutlined, PaperClipOutlined, QuestionCircleOutlined, EnvironmentOutlined, InsertRowBelowOutlined, FallOutlined, ToolOutlined, BarChartOutlined, ApartmentOutlined, PlusOutlined, GiftOutlined} from '@ant-design/icons';
import QIcon from 'components/layout/QIcon';
import type {ItemData as RawItemData} from 'types/layout';
import QLayoutContext from 'contexts/Layout';
import { useTranslation } from 'react-i18next';
import styles from 'styles/layout.scss';
import ReactGA from 'react-ga4';
import GAMap from 'tokens/GAMap.json';

import BillsOutlined from 'assets/icons/Bills-Outlined.svg'
import ManualJournalOutlined from 'assets/icons/ManualJornal-Outlined.svg'
import ReportsOutlined from 'assets/icons/Reports-Outlined.svg'
import CustomersOutlined from 'assets/icons/Customers-Outlined.svg'
import ProductsOutlined from 'assets/icons/Products-Outlined.svg'
import TasksOutlined from 'assets/icons/Tasks-Outlined.svg'
import CustomerReceiptsOutlined from 'assets/icons/Cutsomer Receipts-Outlined.svg'
import ProjectsOutlined from 'assets/icons/Projects-Outlined.svg'
import VendorReceiptsOutlined from 'assets/icons/Vendor Receipts-Outlined.svg'
import FolderOpenOutlined from 'assets/icons/FolderOpen-r.svg'
import PurchasesOrdersOutlined from 'assets/icons/Purchases Orders.svg'
import VendorsOutlined from 'assets/icons/Vendors-Outlined.svg'
import InvoiceOutlined from 'assets/icons/Inovice-Outlined.svg'
import QuotationsOutlined from 'assets/icons/Quotations-Outlined.svg'
import DeductionOutlined from 'assets/icons/Deductions-Outlined.svg'
import LoanOutlined from 'assets/icons/Loan-Outlined.svg'
import SoundOutlined from 'assets/icons/SoundOutlined.svg'
import QFill from 'assets/icons/qFilled.svg'


const {useToken} = theme;
const {Link} = Typography;

const iconMap = new Map<string, React.ReactNode>([
    ['sidepanel_dashboards', <DashboardOutlined />],
    ['sidepanel_advanced_accounting_portal', <DashboardOutlined />],
    ['sidepanel_sales', <DollarOutlined />],
    ['sidepanel_sales__customers', <QIcon component={CustomersOutlined} />],
    ['sidepanel_sales__quotations', <QIcon component={QuotationsOutlined} />],
    ['sidepanel_sales__invoices', <QIcon component={InvoiceOutlined} />],
    ['sidepanel_sales__customer_receipts', <QIcon component={CustomerReceiptsOutlined} />],
    ['sidepanel_purchases', <QIcon component={PurchasesOrdersOutlined} />],
    ['sidepanel_purchases__vendors', <QIcon component={VendorsOutlined} />],
    ['sidepanel_purchases__purchase_orders', <QIcon component={PurchasesOrdersOutlined} />],
    ['sidepanel_purchases__bills', <QIcon component={BillsOutlined} />],
    ['sidepanel_purchases__simple_bills', <QIcon component={BillsOutlined} />],
    ['sidepanel_purchases__vendor_receipts', <QIcon component={VendorReceiptsOutlined} />],
    ['sidepanel_products', <QIcon component={ProductsOutlined} />],
    ['sidepanel_products__products', <QIcon component={ProductsOutlined} />],
    ['sidepanel_products__inventories', <EnvironmentOutlined />],
    ['sidepanel_products__productions', <InsertRowBelowOutlined />],
    ['sidepanel_fixed_assets', <TableOutlined />],
    ['sidepanel_fixed_assets__fixed_assets', <TableOutlined />],
    ['sidepanel_fixed_assets__depreciations', <FallOutlined />],
    ['sidepanel_payrolls', <QIcon component={FolderOpenOutlined} />],
    ['sidepanel_payrolls__payroll_activate', <ToolOutlined />],
    ['sidepanel_payrolls__onboarding_hr', <QIcon component={SoundOutlined} />],
    ['sidepanel_payrolls__employees', <QIcon component={CustomersOutlined} />],
    ['sidepanel_payrolls__payrolls', <QIcon component={FolderOpenOutlined} />],
    ['sidepanel_payrolls__loans', <QIcon component={LoanOutlined} />],
    ['sidepanel_payrolls__bonuses', <GiftOutlined />],
    ['sidepanel_payrolls__deductions', <QIcon component={DeductionOutlined} />],
    ['sidepanel_accounting', <QIcon component={FolderOpenOutlined} />],
    ['sidepanel_accounting__easy_entries', <QIcon component={ManualJournalOutlined} />],
    ['sidepanel_accounting__manual_journal_entries', <QIcon component={ManualJournalOutlined} />],
    ['sidepanel_accounting__accounts', <BarChartOutlined />],
    ['sidepanel_accounting__audit_processes', <ApartmentOutlined />],
    ['sidepanel_projects_tasks', <QIcon component={TasksOutlined} />],    
    ['sidepanel_projects_tasks__projects', <QIcon component={ProjectsOutlined} />],
    ['sidepanel_projects_tasks__tasks', <QIcon component={TasksOutlined} />],
    ['sidepanel_reports', <QIcon component={ReportsOutlined} />],
    ['sidepanel_qoyod_services', <QIcon component={FolderOpenOutlined} />],
    ['sidepanel_qoyod_services__additional_services', <QIcon component={QFill} />],
    ['sidepanel_settings', <SettingOutlined />],
    ['sidepanel_settings__general_settings', <SettingOutlined />],
    ['sidepanel_settings__subscriptions', <SettingOutlined />],
    ['sidepanel_settings__integrations', <RocketOutlined />],
    ['sidepanel_settings__taxes', <SettingOutlined />],
    ['sidepanel_settings__payroll_settings', <SettingOutlined />],
    ['sidepanel_settings__users', <UserOutlined />],
    ['sidepanel_settings__payment_terms', <FileProtectOutlined />],
    ['sidepanel_settings__custom_fields', <FileAddOutlined />],
    ['sidepanel_settings__edit_profile', <EditOutlined />],
    ['sidepanel_settings__attachments', <PaperClipOutlined />],
    ['sidepanel_show_me_how', <QuestionCircleOutlined />],
    ['sidepanel_knowledge_base', <QuestionCircleOutlined />],
    ['sidepanel_qlend', <QIcon component={QFill} />],
    ['sidepanel_qtahseel', <QIcon component={QFill} />]
])

const labelMap = new Map<string, string>([
    ['sidepanel_dashboards', 'activerecord.attributes.layout.dashboard'],
    ['sidepanel_advanced_accounting_portal', 'activerecord.attributes.side_menu.advanced_accountant_portal'],
    ['sidepanel_sales', 'activerecord.attributes.layout.sales'],
    ['sidepanel_sales__customers', 'activerecord.attributes.layout.customers'],
    ['sidepanel_sales__quotations', 'activerecord.attributes.layout.quotations'],
    ['sidepanel_sales__invoices', 'activerecord.attributes.layout.invoices'],
    ['sidepanel_sales__customer_receipts', 'activerecord.attributes.layout.customer_receipts'],
    ['sidepanel_purchases', 'activerecord.attributes.layout.purchases'],
    ['sidepanel_purchases__vendors', 'activerecord.attributes.layout.vendors'],
    ['sidepanel_purchases__purchase_orders', 'activerecord.attributes.layout.orders'],
    ['sidepanel_purchases__bills', 'activerecord.attributes.layout.bills'],
    ['sidepanel_purchases__simple_bills', 'activerecord.attributes.layout.simple_bills'],
    ['sidepanel_purchases__vendor_receipts', 'activerecord.attributes.layout.vendor_receipts'],
    ['sidepanel_products', 'activerecord.attributes.layout.products'],
    ['sidepanel_products__products', 'activerecord.attributes.layout.products'],
    ['sidepanel_products__inventories', 'activerecord.attributes.layout.locations'],
    ['sidepanel_products__productions', 'activerecord.attributes.layout.productions'],
    ['sidepanel_fixed_assets', 'activerecord.attributes.layout.fixed_assets'],
    ['sidepanel_fixed_assets__fixed_assets', 'activerecord.attributes.layout.fixed_assets'],
    ['sidepanel_fixed_assets__depreciations', 'activerecord.attributes.fixed_asset.depreciation'],
    ['sidepanel_payrolls', 'activerecord.attributes.layout.human_resources'],
    ['sidepanel_payrolls__payroll_activate', 'activerecord.attributes.layout.onboarding.activate'],
    ['sidepanel_payrolls__onboarding_hr', 'activerecord.attributes.layout.onboarding.onboarding'],
    ['sidepanel_payrolls__employees', 'activerecord.attributes.layout.employees'],
    ['sidepanel_payrolls__payrolls', 'activerecord.attributes.layout.payrolls'],
    ['sidepanel_payrolls__loans', 'activerecord.attributes.layout.loans'],
    ['sidepanel_payrolls__bonuses', 'activerecord.attributes.layout.bonuses'],
    ['sidepanel_payrolls__deductions', 'activerecord.attributes.layout.deductions'],
    ['sidepanel_accounting', 'activerecord.attributes.layout.accounting'],
    ['sidepanel_accounting__easy_entries', 'activerecord.attributes.layout.easy_entries'],
    ['sidepanel_accounting__manual_journal_entries', 'activerecord.attributes.layout.manual_journal_entries'],
    ['sidepanel_accounting__accounts', 'activerecord.attributes.layout.chart_of_accounts'],
    ['sidepanel_accounting__audit_processes', 'audit_process.menu'],
    ['sidepanel_projects_tasks', 'templates.project_and_task'],
    ['sidepanel_projects_tasks__projects', 'templates.projects.projects'],
    ['sidepanel_projects_tasks__tasks', 'templates.tasks.tasks'],
    ['sidepanel_reports', 'activerecord.attributes.layout.reports'],
    ['sidepanel_qoyod_services', 'activerecord.attributes.layout.qoyod_services'],
    ['sidepanel_qoyod_services__additional_services', 'activerecord.attributes.layout.additional_services'],
    ['sidepanel_settings', 'activerecord.attributes.layout.settings'],
    ['sidepanel_settings__general_settings', 'activerecord.attributes.layout.general_settings'],
    ['sidepanel_settings__subscriptions', 'activerecord.attributes.layout.subscription_settings'],
    ['sidepanel_settings__integrations', 'templates.integration'],
    ['sidepanel_settings__taxes', 'activerecord.attributes.tax.layout.index'],
    ['sidepanel_settings__payroll_settings', 'activerecord.attributes.layout.human_resources_settings'],
    ['sidepanel_settings__users', 'activerecord.attributes.layout.users'],
    ['sidepanel_settings__payment_terms', 'activerecord.attributes.layout.payment_terms'],
    ['sidepanel_settings__custom_fields', 'activerecord.attributes.layout.custom_fields'],
    ['sidepanel_settings__edit_profile', 'activerecord.attributes.layout.edit_profile'],
    ['sidepanel_settings__attachments', 'activerecord.attributes.layout.attachments'],
    ['sidepanel_show_me_how', 'activerecord.attributes.help_content.btn_H'],
    ['sidepanel_knowledge_base', 'frontend.tooltips.on_boarding.knowledge_base'],
    ['sidepanel_qlend', 'activerecord.attributes.layout.qoyod_lend'],
    ['sidepanel_qtahseel', 'activerecord.attributes.layout.qoyod_tahseel']
])

const tagMap = new Map([
    ['beta', {
        label: 'activerecord.attributes.fixed_asset.beta',
        color: 'gold'
    }],
    ['new', {
        label: 'status.new',
        color: 'orange'
    }]
])

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    style?: React.CSSProperties,
    clickAction?: () => void,
    type?: 'group'
  ): MenuItem {
    return {
      key,
      icon,
      children,
      label,
      type,
      style: {height: 'auto', ...style},
      className: styles.q_ant_menu__sub_item,
      onTitleClick: clickAction
    } as MenuItem;
  }
  
function getItems(broken: boolean, isMobileRequest: boolean, t, startIntro: () => void, setOpenKeys, rawItem: RawItemData, style: React.CSSProperties = {}): MenuItem {
    
    if(!rawItem || !rawItem.props) {
        return null;
    }

    
    const {props, children, new_entity, tag} = rawItem;
    let actionElement = null;
    const { id } = props;
    let { href } = props;

    let showMeHow = () => {};
    if(id === 'sidepanel_show_me_how') {
        if(broken) return null;
        showMeHow = startIntro;
    }

    const moduleName = id.split('__')?.[1] ?? id.split('_').slice(1).join('_');
    let [path, newPath, dataToggle, dataTarget] = [href, new_entity?.link, "", ""];
    let mobileWarning = (module) => {};
    
    if(isMobileRequest && href && href !== '#' && !id.startsWith('sidepanel_settings') ) {
        [path, newPath, dataToggle, dataTarget] = ['#', '#', 'modal', `#${moduleName}`]
        mobileWarning = (module) => window.replaceModuleWords(module);
    }
    
    let TagElement = () => null;
    if(tag) {
        const tagData = tagMap.get(tag.type);
        TagElement = () => <Tag color={tagData.color}>{t(tagData.label)}</Tag>
    }

    if(new_entity?.allowed) {

        const newClickAction = () => {
            const createAction = GAMap.sidemenu[id]?.create;
            if(createAction) {
                ReactGA.event({
                    category: GAMap.sidemenu.CATEGORY,
                    action: createAction
                });
            }
            mobileWarning(`new_${moduleName}`)
        }
        
        actionElement = (
            <Button type="link" href={newPath} data-toggle={dataToggle} data-target={`#new_${moduleName}`} onClick={newClickAction} style={{color: 'inherit', display: 'inline'}} icon={ <PlusOutlined /> } />
        )
    }

    href = href || '#';
    
    let itemChildren = null;
    if(children?.length > 0) {
        itemChildren = [];
        children.forEach(child => {
            itemChildren.push(getItems(broken, isMobileRequest, t, startIntro, (() => {}), child, {display: 'flex'}))
        })
    }

    const clickAction = (e: React.MouseEvent<HTMLButtonElement>) => {
        const viewAction = GAMap.sidemenu[id]?.view;
        if(viewAction) {
            ReactGA.event({
                category: GAMap.sidemenu.CATEGORY,
                action: viewAction
            });
        }
        showMeHow();
        mobileWarning(moduleName);
    }

    const openInNewTab = ['sidepanel_knowledge_base', 'sidepanel_advanced_accounting_portal']
    return getItem(
        <Space size={4} style={{display: 'inline-flex', justifyContent: 'space-between', lineHeight: 1.2, width: '100%'}}>
            <Link href={path} data-toggle={dataToggle} data-target={dataTarget} onClick={clickAction} style={{color: 'inherit', whiteSpace: 'break-spaces', wordBreak: 'unset'}} target={openInNewTab.includes(id)? '_blank' : '_self'}>
                <Space size={4}>
                    {t(labelMap.get(id))}
                    <TagElement />
                </Space>
            </Link>
            {actionElement}
        </Space>,
        id,
        iconMap.get(id),
        itemChildren,
        style,
        setOpenKeys.bind(null, [id])
    )
}

interface SideMenuProps {
    startIntro: () => void;
    collapsed: boolean;
    broken: boolean;
}
  
const SideMenu = ({startIntro, collapsed, broken}: SideMenuProps) => {
    const { t } = useTranslation();
    const { sideMenuData, currentActiveItem, currentActiveMenu, isMobileRequest } = useContext(QLayoutContext);
    const [openKeys, setOpenKeys] = useState([currentActiveMenu]);
    const [lastOpenedKeys, setLastOpenedKeys] = useState([currentActiveMenu])
    
    const {token} = useToken();

    const openKey = useCallback((keys) => {
        setOpenKeys((prevState) => prevState?.[0] === keys[0]? [] : keys)
    }, [ openKeys ])

    useEffect(() => {
        if(collapsed) {
            setLastOpenedKeys(openKeys);
            setOpenKeys(undefined);
        } else {
            setOpenKeys(lastOpenedKeys)
        }
    }, [collapsed])
    
    const items = useMemo<MenuItem[]>(() => sideMenuData?.map<MenuItem>(getItems.bind(null, broken, isMobileRequest, t, startIntro, openKey)), [sideMenuData, isMobileRequest, broken]);
    

    return (
        <>
            <ConfigProvider theme={{components: {
                Menu: {
                    darkItemBg: token.colorPrimary,
                    darkSubMenuItemBg: token.colorInfo,
                    darkItemSelectedBg: token.colorInfo
                }
            }}}>
                <Menu items={items}
                    style={{width: '100%'}}
                    theme="dark"
                    openKeys={openKeys}
                    mode="inline"
                    selectedKeys={[currentActiveItem]}
                />
            </ConfigProvider>
        </>
    )
}

export default SideMenu